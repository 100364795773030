import React, {useRef} from 'react';

import {Matcher} from 'react-day-picker';

import bem from 'client/services/bem';
import {parseDate} from 'client/services/utils/date';

import {isDateRange} from 'client/common/inputs/datetime/datepicker-calendar';
import DatepickerCalendar from 'client/common/inputs/datetime/datepicker-calendar/datepicker-calendar';
import Popover from 'client/common/popovers/popover';
import {PopoverRef} from 'client/common/popovers/popover/types';

import {Translation} from 'client/models/language/types';

import {DateInput} from '../datepicker-input';

import cssModule from './datepicker-base.module.scss';

const b = bem('datepicker-base', {cssModule});

export type DatepickerBaseProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabledDates?: Matcher | Matcher[];
  placeholder?: Translation;
  name?: string;
  disabled?: boolean;
  className?: string;
  onBlur?: () => void;
  status?: 'error' | 'warning';
  outputFormat?: 'ISO' | 'ISO_DATE' | 'DATE';
  timezone?: string;
};

export const DatepickerBase = React.forwardRef<HTMLInputElement, DatepickerBaseProps>((props, ref) => {
  const {
    value,
    onChange,
    disabledDates,
    name,
    disabled,
    onBlur,
    placeholder,
    status,
    outputFormat = 'DATE',
    timezone,
  } = props;
  const refPopover = useRef<PopoverRef>(null);

  const handleChangeDay = (date?: string) => {
    onChange?.(date || '');
    refPopover.current?.setOpen(false);
  };

  const handleInputChange = (date?: string) => {
    onChange?.(parseDate(date, {outputFormat, timezone}) || '');
  };

  return (
    <Popover
      ref={refPopover}
      trigger="click"
      role="dialog"
      disableFocus
      triggerClassName={b('trigger')}
      overlay={
        <div>
          <DatepickerCalendar
            {...props}
            timezone={timezone}
            outputFormat={outputFormat}
            mode="single"
            disabled={disabledDates}
            value={value}
            onChange={handleChangeDay}
          />
        </div>
      }
      disabled={disabled}
    >
      <div className={b('input-container')}>
        <DateInput
          className={b('input')}
          name={name}
          ref={ref}
          status={status}
          onBlur={onBlur}
          onChange={handleInputChange}
          disabled={disabled}
          value={isDateRange(value) ? value?.from : (value as string)}
          placeholder={placeholder}
        />
      </div>
    </Popover>
  );
});
