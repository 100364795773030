import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Popover from 'client/common/popovers/popover';
import {PopoverProps} from 'client/common/popovers/popover/types';

import {Translation} from 'client/models/language/types';

import cssModule from './confirmation-popover.module.scss';

const b = bem('confirmation-popover', {cssModule});

type ButtonType = {
  className?: string;
  label?: Translation;
  onClick?: () => any;
};

type ConfirmationPopoverProps = {
  className?: string;
  children: string | React.ReactNode;
  title?: Translation;
  message?: Translation;
  popoverProps?: Omit<PopoverProps, 'children' | 'overlay'>;
  cancelButtonProps?: ButtonType;
  okButtonProps?: ButtonType;
};

const ConfirmationPopover: React.FC<ConfirmationPopoverProps> = (props) => {
  const {className, children, title, message, popoverProps, cancelButtonProps, okButtonProps} = props;
  const lang = useLanguage('COMMON');

  return (
    <Popover
      trigger="click"
      contentClassName={cn(b(), className)}
      offset={10}
      arrowConfig={{
        fill: 'white',
        stroke: '#8996ac',
        tipRadius: 1,
        width: 24,
        height: 12,
      }}
      overlay={({setIsOpen}: {setIsOpen: (state: boolean) => void}) => (
        <div className={b('overlay')}>
          {title && <p className={b('title')}>{title}</p>}
          <p className={b('message')}>{message}</p>
          <div className={b('buttons')}>
            <AppButton
              label={lang.CANCEL}
              transparent
              {...cancelButtonProps}
              onClick={() => {
                cancelButtonProps?.onClick?.();
                setIsOpen(false);
              }}
            />
            <AppButton
              className={b('ok-button')}
              label={lang.OK}
              {...okButtonProps}
              onClick={() => {
                okButtonProps?.onClick?.();
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      )}
      {...popoverProps}
    >
      {children}
    </Popover>
  );
};

export default ConfirmationPopover;
