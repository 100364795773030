import React, {useEffect} from 'react';

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import {IMask, useIMask} from 'react-imask';

import bem from 'client/services/bem';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import 'react-datetime/css/react-datetime.css';

import cssModule from './time-input.module.scss';

const b = bem('time-input', {cssModule});

const maskOptions = {
  mask: Date,
  autofix: 'pad',
  overwrite: true,
  blocks: {
    HH: {mask: IMask.MaskedRange, from: 0, to: 23},
    mm: {mask: IMask.MaskedRange, from: 0, to: 59},
    ss: {mask: IMask.MaskedRange, from: 0, to: 59},
  },
};

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<InferProps<typeof TimeInput.propTypes>> & React.RefAttributes<unknown>>}
 */

const TimeInput = React.forwardRef((props, ref) => {
  const {
    className = '',
    label = '',
    errorMessage = '',
    warningMessage = '',
    withSeconds = false,
    onChange,
    value: initialValue,
    pattern,
    timeConstraints = {},
    required = false,
    ...inputProps
  } = props;

  const timePattern = pattern || (withSeconds ? 'HH:mm:ss' : 'HH:mm');

  const {
    ref: inputRef,
    value: maskedValue,
    setValue,
  } = useIMask(
    {
      pattern: timePattern,
      format: (value) => moment(value).format(timePattern),
      parse: (str) => moment(str, timePattern),
      ...maskOptions,
    },
    {
      onAccept: onChange,
    },
  );

  useEffect(() => {
    if (ref) {
      ref.current = inputRef.current;
    }
  }, [inputRef, ref]);

  return (
    <div
      className={cn(
        b({
          error: !!errorMessage,
          warning: !!warningMessage,
        }),
        className,
      )}
    >
      {label && <span className={b('label')}>{label}</span>}
      <label className={b('field-wrap')}>
        <Datetime
          className={b('picker')}
          viewMode="time"
          dateFormat={false}
          timeFormat={timePattern}
          initialValue={initialValue}
          value={maskedValue}
          onChange={(value) => setValue(moment(value).format(timePattern))}
          timeConstraints={timeConstraints || {}}
          inputProps={{ref: inputRef, className: b('field'), autoComplete: 'off', ...inputProps}}
        />
      </label>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
});

TimeInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  label: TranslationJsx,
  withSeconds: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  pattern: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  timeConstraints: PropTypes.shape({
    hours: PropTypes.object,
    minutes: PropTypes.object,
    seconds: PropTypes.object,
  }),
  required: PropTypes.bool,
};

export default TimeInput;
