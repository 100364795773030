import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useAppMedia} from 'client/services/hooks';

import {selectHeaderLinkBack} from 'client/ducks/header-link-back/selector';
import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';

import AppButton from 'client/common/buttons/app-button';
import {APP_ROLES} from 'client/common/config';
import Icon from 'client/common/icon';

import ClientSwitcher from 'client/components/common/client-switcher';

import HeaderMenu from './header-menu';

import cssModule from './header.module.scss';

const b = bem('header', {cssModule});

const Header = (props) => {
  const {isLoggedUser = false, userRole, showLogoAlways, logo, clients, onClientChange} = props;
  const lang = useLanguage('HEADER');
  const {isTablet} = useAppMedia();
  const showClientSwitcher = userRole !== APP_ROLES.ADMIN && userRole !== APP_ROLES.SUPER_ADMIN && isLoggedUser;
  const link = useSelector(selectHeaderLinkBack);
  const instore = useSelector(selectInstoreTask);

  const history = useHistory();

  const logoImg = <img className={b('logo-img')} src={logo} alt={lang.NO_LOGO} />;
  const responsiveLogo = isTablet ? (
    <>{logoImg}</>
  ) : (
    <Link to="/" className={b('logo-link')}>
      {logoImg}
    </Link>
  );

  const showCustomHeader = !isEmpty(link) && !link.rootPage && isTablet;
  const headerName = instore?.name || link?.name;

  return (
    <header className={b()}>
      <div className={b('inner')}>
        {showCustomHeader ? (
          <div className={b('back')}>
            <AppButton
              onClick={link.callback || history.goBack}
              className={b('back-button')}
              iconName="arrow-left"
              color="light-clients"
            />
            <p className={b('back-label')}>{headerName}</p>
          </div>
        ) : (
          <>
            <div className={isLoggedUser ? b('logo-block') : ''}>
              {showLogoAlways || showClientSwitcher ? (
                <div className={b('logo-block-switcher')}>
                  {logo && responsiveLogo}

                  {showClientSwitcher && (
                    <div className={b('client-switcher')}>
                      <ClientSwitcher clients={clients} onClientChange={onClientChange} />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div className={b('brief', {logged: isLoggedUser})}>
              <Icon name="weezio-logo-colored" />
              <span className={b('brief-title')}>{lang.BRIEF_TITLE}</span>
            </div>
            {isLoggedUser && (
              <div className={b('menu')}>
                <HeaderMenu />
              </div>
            )}
          </>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  onClientChange: PropTypes.func.isRequired,
  showLogoAlways: PropTypes.bool,
  isLoggedUser: PropTypes.bool,
  userRole: PropTypes.string,
  clients: PropTypes.array,
  logo: PropTypes.string,
  lastPageConfig: PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
  }),
};

export default Header;
