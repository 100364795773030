/* eslint-disable */
import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';

import {ACCESS_LEVEL as COMMON_ACCESS_LEVEL} from 'client/models/common/constants';
import {GENDER_TYPES_VALUES as COMMON_GENDER_TYPES_VALUES} from 'client/models/common/constants';

export const NODE_ENV = process.env.NODE_ENV;
export const API_VERSION = process.env.VITE_API_VERSION;
export const API_URL = process.env.VITE_API_URL;
export const API_URL_PROD = process.env.VITE_API_URL_PROD;
export const ADMIN_USER_UPLOADS = 'uploads/admin_user/avatar';
export const API_PATH = `${API_URL}/api/v${API_VERSION}`;
export const IMAGES = 'images';
export const GOOGLE_MAPS_KEY = process.env.VITE_GOOGLE_MAPS_KEY;
export const IS_BROWSER = Boolean(process.env.VITE_IS_BROWSER);
export const IS_DEV_ENV = Boolean(process.env.VITE_IS_DEV_ENV);
export const FACEBOOK_APP_ID = process.env.VITE_FACEBOOK_APP_ID;
export const FACEBOOK_API_VERSION = process.env.VITE_FACEBOOK_API_VERSION;
export const MAX_IMAGE_SIZE = 5242880;
export const HUBSPOT_SCRIPT_SRC = process.env.VITE_HUBSPOT_SCRIPT_SRC;
export const BUGSNAG_API_KEY = process.env.VITE_BUGSNAG_API_KEY;
export const BUGSNAG_RELEASE_STAGE = process.env.VITE_BUGSNAG_RELEASE_STAGE;
export const USERPILOT_APP_TOKEN = process.env.VITE_USERPILOT_APP_TOKEN;
export const PRODUCT_FRUITS_WORKSPACE_CODE = process.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE;
export const HELP_URL = 'https://doc.weezio.net';
export const REFRESH_TOKEN_INITIAL_TIMEOUT = 2000; // 2 sec
export const REFRESH_TOKEN_INTERVAL = 840000; // 14 min
export const WINNING_IDLE_TIME = 600000; // 10 min

export const ADMIN_PAGES = {
  EMAIL_TEMPLATE_EDITOR: '/email-template-editor/:templateId',
};

export const CLIENT_PAGES = {
  DOUBLE_FACTOR_AUTH: '/2-factor-auth',
  ACCESS_DENIED: '/access-denied',
  ADMINS_LIST: '/admins-list',
  AGENCIES: '/agencies',
  AGENCY_CLIENTS: '/agency-clients',
  ANALYSIS: '/analysis',
  AUTOTASK: '/autotask',
  ANIMATIONS: '/animations',
  ANOMALIES: '/anomalies',
  INSTORE_TASKS: '/instore-tasks',

  CATALOG_TEMPLATES: '/catalog/templates',
  CATALOG_MODULES: '/catalog/modules',
  CAMPAIGN: '/campaign',
  CLIENTS_LIST: '/clients-list',
  CLIENTS_LIST_MY: '/my-clients-list',
  COMPANIES: '/companies',
  CONTACTS: '/contacts',
  COOKIES_POLICY: '/cookies-policy',
  CUSTOM_OPERATION_DATABASE: '/custom-operation-database',
  TOKENS: '/tokens',

  DASHBOARDS: '/dashboards',
  DASHBOARDS_HOME: '/dashboards/home',
  DEVICES: '/devices',

  EMAILS: '/emails',
  ERROR_403_URL: '/403',
  ERROR_404_URL: '/404',

  FORMS: '/forms',

  HASHTAG_TRACKER: '/hashtag-trackers',
  HOME: '/',
  WELCOME: '/welcome',
  WAS_DELETED: '/client-was-deleted',

  INTERFACE: '/interfaces',
  INTERFACE_TEMPLATES: '/interface-templates',
  INTERFACE_ITEMS: '/interface-items',

  LEADS_CONFIG: '/leads-config',
  LEADS_EXPORT: '/leads-export',
  LEADS_IMPORT: '/leads-import',
  LEADS_LIST: '/leads-list',
  LEADS_PROFILE: '/leads-profile',
  LOGIN: '/login',
  LOGIN_AUCHAN: '/auchan',
  LOGS: '/logs',

  MAILING_LIST: '/mailing-list',
  MESSAGE_TASK: '/message-task',
  MESSAGE_TASK_RESULTS: '/results',
  MESSAGE_TASK_SETTINGS: '/settings',
  MESSAGE_TASKS: '/message-tasks',
  MONITORING: '/monitoring',

  OPERATIONS: '/operations',
  OPERATIONS_ACTIVE: '/operations/client/active',
  OPERATIONS_FINISHED: '/operations/client/finished',
  OPERATIONS_MY: '/operations/my',

  PARAMETERS: '/parameters',
  PARTICIPANTS: '/participants',
  PARTICIPANTS_LIST: '/participants-list',
  PARTICIPANTS_EXPORT: '/participants-export',
  PARTICIPANTS_CONFIG: '/participants-config',
  PASSWORD_CHANGE: '/password-change',
  PASSWORD_RECOVERY: '/password-recovery',
  PHOTOS: '/photos',
  PLACES: '/places',
  PLANS: '/plans',
  POSTS: '/posts',
  PROFILE: '/profile',
  PRIVACY_POLICY: '/privacy-policy',

  SCENARIO: '/scenarios',
  SCENARIO_RUN_TABLE: '/scenario-run-table',
  SIGN_UP: '/sign-up',
  SSO_CALLBACK: '/auchan-callback',
  SLIDESHOWS: '/slideshows',
  SOURCES: '/sources',
  STORES: '/stores',
  TERMS: '/terms',

  TEMPLATES: '/templates',
  TEST_PARAMETERS: '/test-parameters',
  TRANSLATIONS: '/translations',

  UNSUBSCRIBE: '/unsubscribe',
  UPDATE_PREFERENCES: '/update-preferences',

  VARIABLES: '/variables',
  VISUALS: '/visuals',

  WINNERS: '/winners',

  CLIENTS: '/clients',
  CLIENT_AUTOTASK_TABS: {
    PARTICIPATIONS: '/participations',
    CAMPAIGNS: '/campaigns',
    PLANS: '/plans',
    RESULTS: '/results',
    RESULTS_KPIS: '/results-kpis',
    RESULTS_WINNINGS: '/results-winnings',
    DATA_PARTICIPATIONS: '/data-participations',
    DATA_LEADS: '/data-leads',
    DATA_WINNERS: '/data-winners',
    VISUALS: '/visuals',
    CONFIGURATION: '/configuration',
    CONFIGURATION_OPERATION: '/operation',
    CONFIGURATION_STORES: '/stores',
    CONFIGURATION_DEVICES: '/devices',
    CONFIGURATION_ONLINE: '/online',
    CONFIGURATION_EMAIL_TEMPLATES: '/email-templates',
  },
  CLIENT_INSTORE_TASK_TABS: {
    PLANNING: '/planning',
    WEEK_VIEW: '/week-view',
    REPORTING: '/reporting',
    DASHBOARDS: '/dashboards',
    CONFIGURATION: '/configuration',
  },
  ACCOUNT_PARAMETERS: '/account-parameters',
  USERS: '/users',
};

export const WINNING_PAGES = {
  LOGIN: '/winnings-login',
  WINNING_TASKS: '/winning-tasks',
  WINNING_SEARCH: '/winning-search',
  WINNING: '/winning',
};

export const NAV_GROUPS_MAP = {
  HOME: 'home',
  DATABASES: 'databases',
  CLIENTS: 'clients',
  REFERENCES: 'references',
  OPERATIONS: 'operations',
  ADMINS: 'admins',
  PARAMETERS: 'parameters',
  LEADS: 'leads',
  PARTICIPANTS: 'participants',
  VISUALS: 'visuals',
  TUTORIAL: 'tutorial',
  ORG_PARAMETERS: 'org-parameters',
  PERMANENT_SOURCES: 'permanent_sources',
  DEVICES: 'devices',
  HELP: 'help',
  CATALOGS: 'catalog',
  AGENCY_CLIENTS: 'agency-clients',
};

// sidebar navigation links
export const NAV_LINKS = {
  HOME: CLIENT_PAGES.HOME,
  PARTICIPANTS: CLIENT_PAGES.PARTICIPANTS,
  OPERATIONS: CLIENT_PAGES.OPERATIONS,
  OPERATIONS_MY: CLIENT_PAGES.OPERATIONS_MY,
  OPERATIONS_RUN_TABLE: CLIENT_PAGES.SCENARIO_RUN_TABLE,
  CLIENTS: CLIENT_PAGES.CLIENTS_LIST,
  CLIENTS_MY: CLIENT_PAGES.CLIENTS_LIST_MY,
  LEADS: CLIENT_PAGES.LEADS_LIST,
  VISUALS: CLIENT_PAGES.VISUALS,
  ANALYSIS: CLIENT_PAGES.VISUALS + CLIENT_PAGES.ANALYSIS,
  PLACES: CLIENT_PAGES.PLACES,
  INTERFACE_TEMPLATES: CLIENT_PAGES.INTERFACE_TEMPLATES,
  DEVICES: CLIENT_PAGES.DEVICES,
  PERMANENT_SOURCES: CLIENT_PAGES.SOURCES,
  ADMINS_LIST: CLIENT_PAGES.ADMINS_LIST,
  PARAMETERS: CLIENT_PAGES.PARAMETERS,
  PRIVACY_POLICY: CLIENT_PAGES.PRIVACY_POLICY,
  COOKIES_POLICY: CLIENT_PAGES.COOKIES_POLICY,
  TRANSLATIONS: CLIENT_PAGES.TRANSLATIONS,
  CATALOG_TEMPLATES: CLIENT_PAGES.CATALOG_TEMPLATES,
  CATALOG_MODULES: CLIENT_PAGES.CATALOG_MODULES,
  AGENCY_CLIENTS: CLIENT_PAGES.AGENCY_CLIENTS,

  OPERATIONS_ACTIVE: CLIENT_PAGES.OPERATIONS + '/client/active',
  OPERATIONS_FINISHED: CLIENT_PAGES.OPERATIONS + '/client/finished',
  TUTORIAL: '#',
};

export const PAGES_BACKGROUND_COLORS = {
  LOGIN: '#E5E5E5',
  ADMINS: '#E7E5EE',
  AGENCY: '#E7E5EE',
  STORES: '#E7E5EE',
  PLACES: '#DCE4E5',
  DEVICES: '#EFECE7',
  INTERFACE_TEMPLATES: '#EFECE7',
  OPERATIONS: '#F7F7F7',
  LEADS: '#E2E7EA',
  CLIENTS: '#E7E5EE',
  AUTOTASK: '#EFECE7',
  AUTOTASK_SCENARIO: '#EBEEF1',
  TEST_PARAMETERS: '#EBEEF1',
  VISUALS: '#E8E1E5',
  POSTS: '#E8E1E5',
  HASHTAG_TRACKER: '#E8E1E5',
  DASHBOARD: '#EDEDFA',
  SLIDESHOW: '#E8E1E5',
  CAMPAIGN: '#eaebe4',
  SOURCES: '#eaebe4',
  TEMPLATES: '#EFECE7',
  BROADCASTING: '#EFECE7',
  PARAMETERS: '#EBEEF1',
  PRIVACY_POLICY: '#E6ECEB',
  COOKIES_POLICY: '#E6ECEB',
  PARTICIPATIONS: '#E6ECEB',
  CLIENT_SIDE: '#F7F7F7',
  PROFILE: '#F7F7F7',
  TOKENS: '#EFECE7',
  ADMIN_EMAIL_TEMPLATE_EDITOR: '#FFFFFF',
  WINNINGS_MOBILE: '#FFFFFF',
};

export const APP_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
  TRACKING_USER: 'TRACKING_USER',
  ANON: 'ANON',
};

/**
 * @deprecated use ACCESS_LEVEL from 'client/models/common/constants';
 * this variable is not typed by TS, so it was moved to that folder for right typing
 */
export const USER_MEMBERSHIP = {
  NATIONAL: ACCESS_LEVEL_TYPES.NATIONAL,
  REGION: ACCESS_LEVEL_TYPES.REGIONS,
  STORE: ACCESS_LEVEL_TYPES.STORES,
  CLIENT_ADMIN: ACCESS_LEVEL_TYPES.CLIENT_ADMIN,
  LOCAL: ACCESS_LEVEL_TYPES.LOCAL,
};

/**
 * @deprecated use ACCESS_LEVEL from 'client/models/common/constants';
 * this variable is not typed by TS, so it was moved to that folder for right typing
 */
export const ACCESS_LEVEL = COMMON_ACCESS_LEVEL;

/**
 * @type {Record<import('client/models/common/types').AccessLevelType, number>}
 */
export const ACCESS_LEVEL_ORDER = {
  [COMMON_ACCESS_LEVEL.LOCAL]: 1,
  [COMMON_ACCESS_LEVEL.STORE]: 2,
  [COMMON_ACCESS_LEVEL.REGION]: 3,
  [COMMON_ACCESS_LEVEL.NATIONAL]: 4,
  [COMMON_ACCESS_LEVEL.CLIENT_ADMIN]: 5,
  [COMMON_ACCESS_LEVEL.ADMIN]: 5,
};

export const USER_TYPES = {
  ADMIN_USER: 'AdminUser',
  CLIENT_USER: 'ClientUser',
};

export const API_METHODS = {
  ADMIN_USERS: '/admin_users',
  AGENCIES: '/agencies',
  AGENCY_MEMBERSHIPS: '/agency_memberships',
  ANONYMOUS_EMAILS: '/anonymous_messages',
  ANONYMOUS_LEADS: '/anonymous_leads',
  AVAILABLE_DOMAINS: '/available_domains',
  API_LEAD_IMPORTS: '/api_lead_imports',
  API_LEAD_EXPORT: '/api_lead_exports',
  API_PARTICIPATION_EXPORT: '/api_participation_exports',
  AUTOMATIC_VISUAL_GROUPS: '/automatic_visual_groups',
  AUTOMATION_TASKS: '/automation_tasks',
  AUCHAN_SSO_LOGIN: '/users/saml_authenticate',

  BRANCH_SCENARIO_STEPS: '/branch_scenario_steps',
  BRANCH_SCENARIO_STEP_CONDITIONS: '/branch_scenario_step_conditions',
  BROADCAST_LISTS: '/broadcast_lists',
  BROADCAST_RECIPIENTS: '/broadcast_recipients',
  BROADCAST_LIST_DISPLAY_ITEMS: '/broadcast_list_display_items',
  BULK_ACTION: '/bulk_action',
  HISTORY_CHANGES: '/history_changes/pretty',

  CAMPAIGNS: '/campaigns',
  CATEGORIES: '/categories',
  CITIES: '/cities',
  CLIENT_USERS: '/client_users',
  CLIENTS: '/clients',
  CLIENTS_DOMAINS: '/client_domains',
  CLIENTS_SIREN_NUMBER: '/clients/check_siren',
  CHECK_AUTH_TOKEN: '/users/confirm_access_token',
  COMPANIES: '/companies',
  COLUMN_ADAPTER: '/column_adapters',
  COLUMN_ADAPTER_PERMITTED: '/column_adapters/permitted',
  COLUMN_VISIBILITY_ITEMS: '/column_visibility_items',
  COMPANY_MEMBERSHIPS: '/company_memberships',

  DASHBOARDS: '/dashboards',
  DEVICE_FAMILIES: '/device_families',
  DEVICES: '/devices',
  DEVICES_EXTERNAL: '/devices/external',
  DEVICES_EXTERNAL_FILTER: '/devices/external_filter',
  DUPLICATE: '/duplicate',
  DEVICE_REPUBLISH: '/device_republish',
  DEVICE_AFFECTATIONS: '/device_affectations',

  EMAIL_TASKS: '/email_tasks',
  EMAIL_TEMPLATE_LINKS: '/email_template_links',
  EMAIL_TEMPLATES_NEW: '/email_templates/new',
  EMAIL_TEMPLATES: '/operation_email_templates',
  EMAIL_SENDERS: '/email_senders',
  FONTS: '/fonts',
  MASTER_EMAIL_TEMPLATES: '/master_email_templates',
  GALLERY_EMAIL_TEMPLATES: '/gallery_email_templates',
  REPLACE_EMAIL_TEMPLATE: '/replace_template',

  FACEBOOK_ACCOUNTS: '/facebook_accounts',
  FACEBOOK_POST_TASK_CONFIGURATIONS: '/facebook_post_task_configurations',
  FILE_SOCIAL_SCENARIO_STEP_IMAGES: '/file_social_scenario_step_images',
  FILE_LEAD_IMPORTS: '/file_lead_imports',
  FILE_LEAD_EXPORT: '/file_lead_exports',
  FILE_PARTICIPATION_EXPORT: '/file_participation_exports',
  FILE_WINNER_EXPORT: '/file_winner_exports',
  FORMS: '/forms',
  FORM_ITEM_ACCESSES: '/form_item_accesses',

  GROUP: '/group',
  GROUPS: '/groups',
  GRAPHS: '/graphs',
  GRAPHS_DATA: '/data',
  GRAPH_POSITIONS: '/graph_positions',

  INSTANT_LOTTERY_SCENARIO_STEPS: '/instant_lottery_scenario_steps',
  INTERNAL_INTERFACES: '/internal_interfaces',
  INTERFACES: '/interfaces',
  INTERFACE_TEMPLATE_ITEMS: '/interface_template_items',
  INTERFACE_TEMPLATES: '/interface_templates',
  INTERFACE_ITEMS: '/interface_items',
  INTERFACE_LEVELS: '/interface_levels',

  LEADS: '/leads',
  LEADS_WITH_IMPORTED: '/leads_with_imported',
  LEADS_COLUMN: '/lead_columns',
  LEADS_DISPLAY_ITEMS: '/lead_display_items',
  LEAD_HISTORIES: '/lead_histories',
  LEAD_FIRST_SOURCES: '/lead_first_sources',
  LEAD_TRANSFERS: '/lead_transfers',
  LEAD_TRANSFER_REPORTS: '/lead_transfer_reports',
  LEAD_IMPORT_REPORTS: '/lead_import_reports',
  LEAD_EXPORT_REPORTS: '/lead_export_reports',
  LEADS_REPORTS: '/lead_transfer_reports',
  LEADS_OPTIN_STATISTICS: '/statistics/opt_in_leads',
  LEADS_STATISTIC: '/statistics/leads',
  LEADS_TIMELINE: '/statistics/leads_timeline',
  ONLINE_USERS_STATISTIC: '/statistics/online_users',
  CAMPAIGN_LEADS_STATISTIC: '/statistics/campaign_leads',
  PROFILE: '/profile',

  LOGIN: '/users/authenticate',
  SEND_OTP_CODE: '/users/send_otp_code',
  LOGOUT: '/users/log_out',

  TRACKING_USER_LOGIN: '/prize_tracking_users/authenticate',
  TRACKING_USER_LOGOUT: '/prize_tracking_users/log_out',
  TRACKING_USER_REFRESH_TOKEN: '/prize_tracking_users/refresh_token',

  LOTTERY_SCENARIO_STEP_PRIZES: '/lottery_scenario_step_prizes',

  MANUAL_VISUAL_GROUPS: '/manual_visual_groups',
  MANUAL_VISUAL_GROUP_ITEMS: '/manual_visual_group_items',
  MEDIA_STORAGES: '/media_storages',
  MEMBERSHIPS: '/memberships',
  MEMBERSHIPS_TEAM_USERS: '/memberships/team_users',
  MESSAGE_SCENARIO_STEP_TO_OPT_IN_COLUMNS: '/message_scenario_step_to_opt_in_columns',
  MESSAGE_TASKS: '/message_tasks',
  MESSAGES: '/messages',
  MESSAGE_TASK_TOGGLE_STATE: '/toggle_state',
  MESSAGE_SENDERS: '/message_senders',
  MESSAGE_TASK_MAPPING_ITEMS: '/message_task_mapping_items',

  NETWORKS: '/networks',

  OFFLINE_INTERACTIONS: '/offline_interactions',
  PLACES_WITH_DEVICES: '/places_with_devices',
  GROUP_OFFLINE_INTERACTIONS: '/offline_interactions/grouped_by_place',
  ONLINE_INTERACTIONS: '/online_interactions',
  OPERATION_DATA: '/operation_data',
  OPERATION_DATUM_COLUMNS: '/operation_datum_columns',
  OPERATION_DATUM_DISPLAY_ITEMS: '/operation_datum_display_items',
  OPERATIONS: '/operations',
  OPERATION_EMAIL_TEMPLATES: '/operation_email_templates',
  EMAIL_TEMPLATE_KINDS: '/email_template_kinds',
  EMAIL_TEMPLATE_IMAGES: '/email_template_images',
  MJML_PARSE: '/mjml_parse',
  COUPONS: '/coupons',
  COUPON_TOKENS: '/coupon_tokens',
  OPT_IN_COLUMNS: '/opt_in_columns',
  OPT_IN_MAPPING_ITEMS: '/opt_in_mapping_items',

  PARTICIPATIONS_LEVEL_STATISTIC: '/statistics/interface_levels',
  PARTICIPATIONS_TIMELINE_STATISTIC: '/statistics/participations_timeline',
  PARTICIPATION_DISPLAY_ITEMS: '/participation_display_items',
  PARTICIPATION_PRIZES: '/participation_prizes',
  PARTICIPATION_PRIZENAMES_AVAILABLE: '/available_prize_names',
  PARTICIPATION_EXPORT_ITEMS: '/participation_export_items',
  PARTICIPATION_EXPORT_REPORTS: '/participation_export_reports',
  PARTICIPATIONS: '/participations',
  PASSWORD_CHANGE: '/users/password',
  PASSWORD_RECOVERY: '/users/password',
  PREFORM: '/perform',
  PLACES: '/places',
  PLACES_WITH_FILTER: '/places_with_filter',
  PLANS: '/plans',
  POSSIBLE_VARIABLES: '/possible_variables',
  POSTED_VISUALS: '/posted_visuals',
  PRIVACY_POLICY: '/privacy_policies',
  PRIVACY_POLICY_ACCEPT: '/users/:id/accept_privacy_policy',

  REGIONS: '/regions',
  REGION_ACCESS: '/region_accesses',

  SCENARIOS: '/scenarios',
  SCENARIO_EXECUTION_LOGS: '/scenario_execution_logs',
  SCENARIO_EXECUTIONS: '/scenario_executions',
  SCENARIO_STEPS: '/scenario_steps',
  SCENARIO_VARIABLES: '/scenario_variables',
  SCENARIO_PRIZES: '/branch_scenario_step_conditions_prizes',
  SIGN_UP: '/users/invitation',
  SG_INTERFACES: '/survey_gizmo_interfaces',
  SG_INTERFACES_NEW: '/survey_gizmo_interfaces/new',
  SLIDESHOWS: '/diaporamas',
  SLIDESHOW_MAPPING_ITEMS: '/diaporama_mapping_items',
  SLIDESHOW_VISUAL_GROUPS: '/diaporama_visual_groups',
  SMS_SENDERS: '/sms_senders',
  SMS_TASKS: '/sms_tasks',
  SOCIAL_ACCOUNTS: '/social_accounts',
  SOCIAL_SCENARIO_STEP_IMAGES: '/social_scenario_step_images',
  SOURCES: '/sources',
  PERMANENT_SOURCES: '/permanent_sources',
  STATISTICS_PARTICIPATIONS: '/statistics/participations',
  STATISTICS_SOURCES: '/statistics/sources',
  STOP: '/stop',
  SUBCATEGORIES: '/subcategories',
  SUBSIDIARIES: '/subsidiaries',
  SYSTEM_COLUMN_ADAPTERS: '/system_column_adapters',

  TASK_INDEX_ITEMS: '/task_index_items',
  TASK_COMMENTS: '/task_comments',
  TEMPLATE: '/template',
  TEMPLATES: '/templates',
  TOAST_NOTIFICATIONS: '/toast_notifications',

  TEMPLATE_ANSWERS: '/template_answers',
  TWITTER_HASHTAG_TRACKERS: '/twitter_hashtag_trackers',
  TWITTER_HASHTAG_TRACKERS_NEW: '/twitter_hashtag_trackers/new',
  TWITTER_POST_TASK_CONFIGURATIONS: '/twitter_post_task_configurations',

  UNBOUNCE_INTERFACES: '/unbounce_interfaces',
  UNBOUNCE_INTERFACES_NEW: '/unbounce_interfaces/new',
  UPDATE_PASSWORD: '/update_password',
  USERS: '/users',
  PRIZE_TRACKING_USERS: '/prize_tracking_users',
  USER_PASSWORD_EXPIRATION_CONFIGS: '/user_password_expiration_configs',

  VARIABLES: '/variables',
  VERIFY_TOKEN: '/verify_token',
  VISUAL_GROUPS: '/visual_groups',
  VISUALS: '/visuals',
  VISUAL_DISPLAY_ITEMS: '/visual_display_items',

  WINNERS: '/winners',
  WINNER_EXPORT_REPORTS: '/winner_export_reports',

  DIAGRAMS: '/diagrams',
  CLIENT_DIAGRAMS: '/diagrams/client',

  GAMES: '/games',
  PRIZES: '/prizes',
  PRIZE_MAPS: '/prize_maps',
  PRESENT_WINNER: '/present_winner',

  GLOBAL_MULTIMEDIA_FILE: '/global_media_files',
  LOCAL_MULTIMEDIA_FILE: '/local_media_files',

  INSTORE_TASKS: '/instore_tasks',
  INSTORE_TASKS_NETWORKS: '/instore/task_networks',
  INSTORE_TASKS_ACCESSES: '/instore_task_accesses',
  INSTORE_TASKS_POSITIONS: '/instore/task_positions',
  INSTORE_TASKS_KITS: '/instore/kits',
  INSTORE_TASKS_KIT_MAPPINGS: '/instore/kit_mappings',
  INSTORE_TASKS_ANIMATION_PHOTOS: '/instore/animation_photos',
  INSTORE_TASKS_MONITORING: '/instore/animation_kpis',
  INSTORE_TASK_KPIS: '/instore/task_kpis',
  ANIMATIONS: '/instore/animations',
  ANIMATIONS_STATISTICS: '/instore/animations/statistics',
  ANOMALY_OCCURRENCES: '/instore/anomaly_occurrences',
  ANIMATION_KPIS: '/instore/animation_kpis',
  ANIMATION_QUESTIONS: '/instore/animation_kpis/questions',
  INSTORE_TASKS_STATS_FILES: '/instore/stats_files',
  INSTORE_DASHBOARDS: '/instore/dashboards',
  INSTORE_DASHBOARD_BLOCKS: '/instore/dashboard_blocks',
  INSTORE_INTERNAL_DASHBOARDS: '/instore/internal_dashboards',

  WINNING_PRIZES: '/winning_prizes',
  WEB_APPS: '/web_apps',
  WEB_APP_PUBLISH: '/web_app_publish',
  WEB_APP_REPUBLISH: '/web_app_republish',
  WEB_APP_UNPUBLISH: '/web_app_unpublish',
  WEB_APP_TESTING: '/web_app_testing',
  SEARCH_WINNER: '/search_winner',

  CATALOGS: '/catalogs',
  CLIENT_TEMPLATES: '/client_templates',
  CLIENT_TEMPLATE_NAMES: '/client_template_names',

  DATA_TAB_ACCESS: '/data_tab_accesses',

  SMS_TEMPLATE_KINDS: '/sms_template_kinds',
  OPERATION_SMS_TEMPLATES: '/operation_sms_templates',

  TRANSLATIONS: '/translations',
  TRANSLATION_ITEMS: '/translation_items',
  TEMPLATE_GROUPS: '/template_groups',

  LANGUAGES: '/languages',

  REMOVE_IMAGES: '/remove_images',
  STATISTIC_EXPORTS: '/statistic_exports',
  SOURCE_STORES: '/source_stores',
  CRMS: '/crms',
};

export const PROFILE = {
  PROJECT_MANAGER: 'project_manager',
  SUPER_ADMIN: 'super_admin',
  ACCOUNT_MANAGER: 'account_manager',
  TECHNICAL_STAFF: 'technical_staff',
  ADMIN: 'admin',
  USER: 'user',
};

export const STATUS_COLORS = {
  5: '#ab32cd',
  4: '#0b95de',
  3: '#ec7930',
  2: '#0eaa58',
  true: '#0eaa58',
  1: '#b0c4cb',
  false: '#b0c4cb',
  0: '#fff',
};

export const RUN_TABLE_STATUS_COLORS = {
  sleeping: '#0b95de',
  performing: '#0b95de',
  stopped: '#ec7930',
  failed: '#D23C53',
  finished: '#0EAA58',
};

export const VISUALS_STATUS_COLORS = {
  validated: '#0EAA58',
  refused: '#D23C53',
  canceled: '#AFBBC4',
};

// TODO: Get scenario step implementation type from backend

export const SCENARIO_STEP_TYPES = {
  timer: 'TimerScenarioStep',
  branch: 'BranchScenarioStep',
  calculation: 'CalculationScenarioStep',
  message: {
    sms: 'SmsScenarioStep',
    email: 'EmailScenarioStep',
    inherit: 'MessageScenarioStep',
  },
  social: {
    facebook: 'FacebookScenarioStep',
    twitter: 'TwitterScenarioStep',
    inherit: 'SocialScenarioStep',
  },
  instantLottery: 'InstantLotteryScenarioStep',
  prizeDraw: 'ScheduledLotteryScenarioStep',
  coupons: 'CouponScenarioStep',
  weezioCoupons: 'WeezioCouponScenarioStep',
  sogecCoupons: 'SogecCouponScenarioStep',
  appCoupons: 'AppCouponScenarioStep',
  // ...
};

// values are keys for en.json

export const SCENARIO_STEP_NAMES = {
  [SCENARIO_STEP_TYPES.timer]: 'TIMER_STEP',
  [SCENARIO_STEP_TYPES.branch]: 'BRANCH_STEP',
  [SCENARIO_STEP_TYPES.calculation]: 'CALCULATION_STEP',
  [SCENARIO_STEP_TYPES.message.email]: 'SEND_EMAIL_STEP',
  [SCENARIO_STEP_TYPES.message.sms]: 'SEND_SMS_STEP',
  [SCENARIO_STEP_TYPES.social.facebook]: 'POST_FB_STEP',
  [SCENARIO_STEP_TYPES.social.twitter]: 'POST_TW_STEP',
  [SCENARIO_STEP_TYPES.instantLottery]: 'INSTANT_WIN_STEP',
  [SCENARIO_STEP_TYPES.prizeDraw]: 'PRIZE_DRAW_STEP',
  [SCENARIO_STEP_TYPES.coupons]: 'COUPON_STEP',
  [SCENARIO_STEP_TYPES.weezioCoupons]: 'WEEZIO_COUPON_STEP',
  [SCENARIO_STEP_TYPES.appCoupons]: 'APP_COUPON_STEP',
  [SCENARIO_STEP_TYPES.sogecCoupons]: 'SOGEC_COUPON_STEP',
};

export const DEFAULT_LANGUAGE = process.env.VITE_DEFAULT_LANGUAGE;

export const getAppLanguage = (languages = []) => {
  let storedLang = localStorage.getItem('language') || null;
  const defaultAppLanguages = ['en', 'fr'];
  const appLanguages = languages?.length ? languages : defaultAppLanguages;
  storedLang = appLanguages.includes(storedLang) ? storedLang : null;

  const browserLang = navigator.language.includes('fr') ? 'fr' : null;

  return storedLang || browserLang || DEFAULT_LANGUAGE;
};

/* eslint-enable */

// operation page type

export const OPERATIONS_TYPES = {
  ALL: 'ALL',
  MY: 'MY',
};

// possible transfer types in system

export const LEAD_TRANSFER_TYPES = {
  EXPORT: 'FileLeadExport',
  IMPORT: 'FileLeadImport',
  API_EXPORT: 'ApiLeadExport',
  API_IMPORT: 'ApiLeadImport',
};

// possible transfer types in system

export const LEAD_TRANSFER_REPORT_TYPES = {
  EXPORT: 'LeadExportReport',
  IMPORT: 'LeadImportReport',
};

export const LEAD_IMPORT_TYPES = {
  LEAD_IMPORT_ITEM: 'LeadImportItem',
};

export const LEAD_EXPORT_TYPES = {
  LEAD_EXPORT_ITEM: 'LeadExportItem',
};

export const PARTICIPATION_EXPORT_TYPES = {
  PARTICIPATION_EXPORT_ITEM: 'ParticipationExportItem',
};

export const LEAD_IMPORT_EXPORT_FILE_TYPES = {
  XLS: 'xlsx',
  CSV: 'csv',
};

export const LEAD_IMPORT_UPDATE_TYPES = {
  UPDATE_ALL: 'always',
  UPDATE_ONLY_EMPTY_FIELDS: 'if_target_blank',
  UPDATE_EXCEPT_EMPTY: 'if_source_present',
  UPDATE_NOTHING: 'never',
};

// possible task types in system

// client types in system

export const CLIENT_TYPES = {
  COMPANY: 'Company',
  AGENCY: 'Agency',
};

export const INTERFACE_TYPES = {
  INTERNAL: 'InternalInterface',
  SURVEY_GIZMO: 'SurveyGizmoInterface',
  UNBOUNCE: 'UnbounceInterface',
};

export const INTERFACE_FAMILIES = {
  BUZZEO_APP: 'BUZZEO_APP',
  SURVEY_GIZMO: 'SURVEY_GIZMO',
  UNBOUNCE: 'UNBOUNCE',
};

export const SOURCE_TYPES = {
  email: 'EMAIL',
  facebook_ad: 'FACEBOOK_AD',
  google_ad: 'GOOGLE_AD',
  nfc: 'NFC',
  qr_code: 'QR_CODE',
  sms: 'SMS',
  website: 'WEBSITE',
};

export const CONDITION_COLUMN_TYPES = {
  ANOTHER_COLUMN: 'another_column',
  VIRTUAL_COLUMN: 'virtual_column',
  VALUE: 'value',
  RUNTIME: 'runtime',
  SYSTEM_DATE: 'system_date',
  SYSTEM_TIME: 'system_time',
};

export const COLUMN_TYPES = {
  BOOLEAN: 'boolean',
  TEXT: 'text',
  STRING: 'string',
  EMAIL: 'email',
  DECIMAL: 'decimal',
  PHONE: 'phone',
  FILE: 'file',
  DATETIME: 'datetime',
  INTEGER: 'integer',
};

export const STATUS_TYPES = {
  VALIDATED: 'validated',
  REFUSED: 'refused',
  CANCELED: 'canceled',
};

export const GROUP_TYPES = {
  AUTOMATIC: 'AutomaticVisualGroup',
  MANUAL: 'ManualVisualGroup',
};

export const DATABASES = {
  LEADS: 'Lead',
  VISUALS: 'Visual',
  CUSTOM_OPERATION: 'OperationDatum',
  VISUALS1: 'Visual1',
  VISUALS2: 'Visual2',
  VISUALS3: 'Visual3',
  CITY: 'City',
  AGGLOMERATION: 'Agglomeration',
  ZONE: 'Zone',
  PLACE: 'Place',
  CLIENT: 'Client',
  INTERFACE: 'Interface',
  GROUP: 'Group',
  NETWORK: 'Network',
  EMAIL_TEMPLATE: 'EmailTemplate',
  EMAIL_TEMPLATE_LINK: 'EmailTemplateLink',
  EMAIL_TEMPLATE_LINK_CLICK: 'EmailTemplateLinkClick',
  INTERACTION: 'Interaction',
  MESSAGE_TASK: 'MessageTask',
  MESSAGE: 'Message',
  OPERATION: 'Operation',
  PARTICIPATION: 'Participation',
  PLAN: 'Plan',
  POST_TASK: 'PostTask',
  SCENARIO: 'Scenario',
  SOCIAL_ACCOUNT: 'SocialAccount',
  SOURCE: 'Source',
  TWITTER_HASHTAG_TRACKER: 'TwitterHashtagTracker',
  DEVICE: 'Device',
  DEVICE_FAMILY: 'DeviceFamily',
  REGION: 'Region',
  AUTOMATION_TASK: 'AutomationTask',
  BROADCAST_LIST: 'BroadcastList',
  CAMPAIGN: 'Campaign',
  OPT_IN_TYPES: 'OptInColumn',
  EXECUTION_STEP: 'ScenarioStepExecution',
  SCENARIO_STEP: 'ScenarioStep',
  POST: 'Post',
  OPT_IN: 'OptIn',
  SCENARIO_EXECUTION: 'ScenarioExecution',
};

// Possible timer names in system.
export const TIME_UNITS = {
  seconds: {
    name: 'seconds',
    localeKey: 'SECONDS',
  },
  minutes: {
    name: 'minutes',
    localeKey: 'MINUTES',
  },
  hours: {
    name: 'hours',
    localeKey: 'HOURS',
  },
  days: {
    name: 'days',
    localeKey: 'DAYS',
  },
  weeks: {
    name: 'weeks',
    localeKey: 'WEEKS',
  },
  months: {
    name: 'months',
    localeKey: 'MONTHS',
  },
  quarters: {
    name: 'quarters',
    localeKey: 'QUARTERS',
  },
  semesters: {
    name: 'semesters',
    localeKey: 'SEMESTERS',
  },
  years: {
    name: 'years',
    localeKey: 'YEARS',
  },
};

// Possible genders in system.
// Key: what we get from api.
// Value: name in locale file (en.json, fr.json)
// this object is useful when add localization
export const GENDER_TYPES = {
  'M.': 'MALE',
  'Mme.': 'FEMALE',
};

export const FILTERED_GENDER_TYPES = {
  Monsieur: 'MALE_RADIO',
  Madame: 'FEMALE_RADIO',
};

/**
 * @deprecated use GENDER_TYPES_VALUES from 'client/models/constants'
 */
export const GENDER_TYPES_VALUES = COMMON_GENDER_TYPES_VALUES;

// Possible lead source types.
export const LEAD_SOURCE_TYPES = {
  API_LEAD_IMPORT: 'ApiLeadImport',
  PARTICIPATION: 'Participation',
  FILE_LEAD_IMPORT: 'FileLeadImport',
  OPERATION: 'Operation',
};

// possible column adapter types in system
export const COLUMN_ADAPTER_TYPES = {
  ASSOCIATION: 'AssociationColumnAdapter',
  INTERNAL: 'InternalColumnAdapter',
  EXTERNAL: 'ExternalColumnAdapter',
  SYSTEM: 'SystemColumnAdapter',
  OPT_IN: 'OptInColumnAdapter',
};

// possible display items in system
export const DISPLAY_ITEM_TYPES = {
  OPERATION_DATUM: 'OperationDatumDisplayItem',
  LEAD: 'LeadDisplayItem',
  VISUAL: 'VisualDisplayItem',
  BROADCAST_LIST: 'BroadcastListDisplayItem',
};

// possible kinds of manual data generator item
export const GENERATOR_KINDS = {
  FIXED_VALUE: 'fixed',
  SYSTEM_DATA: 'system_data',
  CURRENT_DAY: 'current_day',
  CURRENT_TIME: 'current_time',
  CLIENT_VALUE: 'client_value',
};

export const MESSAGE_TASK_STATES = {
  PERFORMING: 'performing',
  NEW: 'new',
  FINISHED: 'finished',
  SLEEPING: 'sleeping',
};

export const LINK_KINDS = {
  MIRROR: 'mirror',
  REGULAR: 'regular',
  UNSUBSCRIBE: 'unsubscribe',
  UPDATE_SETTINGS: 'update_settings',
};

export const MESSAGE_SENDERS_TYPES = {
  SMS: 'SmsSender',
  EMAIL: 'EmailSender',
};

export const SOCIAL_ACCOUNT_TYPES = {
  FACEBOOK: 'FacebookAccount',
  TWITTER: 'TwitterAccount',
};

export const VISUAL_INTERACTION_TYPES = {
  OFFLINE: 'OfflineInteraction',
};

export const POST_PREVIEW_TYPES = {
  MAIN: 'main',
  HASHTAG_TRACKER: 'hashtagtracker',
  DIAPORAMA: 'diaporama',
};

export const GRAPH_KINDS = {
  MAP: 'map',
  DONUT: 'donut',
  HORIZONTAL_BARS: 'horizontal_bars',
  HORIZONTAL_TABLE: 'horizontal_table',
  LINE: 'line',
  METRICS: 'metrics',
  VERTICAL_BARS: 'vertical_bars',
  VERTICAL_TABLE: 'vertical_table',
};

export const OPERATORS = {
  IN: 'in',
  INCLUDES: 'includes',
  GREATER: 'gt',
  LESS: 'lt',
  EQUALS: 'eq',
  NOT_EQUALS: 'not_eq',
  BETWEEN: 'between',
};

export const CLIENT_LEVEL_TYPES = {
  CLIENT: 'Client',
  OPERATION: 'Operation',
  AUTOMATION_TASK: 'AutomationTask',
  MESSAGE_TASK: 'MessageTask',
  POST_TASK: 'PostTask',
};

export const DATA_GENERATOR_TYPES = {
  AUTO: 'auto',
  MANUAL: 'manual',
  MAP: 'map',
  TIME: 'time',
};

export const TIME_DATA_GENERATOR_KINDS = {
  FIXED: 'fixed',
  OFFSET: 'offset',
  AUTO: 'auto',
};

export const TIME_DATA_GENERATOR_OFFSET_KINDS = {
  FIXED: 'fixed',
  CURRENT: 'current',
};

export const DASHBOARD_KIND = {
  HOME: 'home',
  LEADS: 'leads',
  REGULAR: 'regular',
  VISUALS: 'visuals',
};

export const PERMITTED_COLUMN_ADAPTERS_SCOPES = {
  MAP_DATA_GENERATOR: 'map_data_generator',
  AUTO_DATA_GENERATOR: 'auto_data_generator',
  Y_AXIS_DATA_GENERATOR: 'y_axis_data_generator',
  CONDITION: 'condition',
  GRAPH_CLIENT_VALUE: 'graph',
};

export const UNITY_KINDS = {
  HOURS: 'hours',
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  QUARTERS: 'quarters',
  SEMESTERS: 'semesters',
  YEARS: 'years',
};

export const UNITY_KINDS_TIME_FORMAT = {
  HOURS: {
    LONG: 'YYYY MMMM DD - HH [h]',
    SHORT: 'HH [h]',
  },
  DAYS: {
    LONG: 'MMMM YYYY - DD',
    SHORT: 'DD',
  },
  MONTHS: 'MMMM - YYYY',
  YEARS: 'YYYY',
};

export const GRAPHS_DATA_RESERVED_Y_KEYS = {
  LONGITUDE: 'longitude',
  LATITUDE: 'latitude',
  TYPE: 'type',
};

export const PLACES_TYPES = {
  CITY: 'City',
  AGGLOMERATION: 'Agglomeration',
  ZONE: 'Zone',
  PLACE: 'Place',
};

export const MEMBERSHIPS_TYPES = {
  AGENCY: 'AgencyMembership',
  COMPANY: 'CompanyMembership',
};

export const OPERATION_STATUS_TYPES = {
  ACTIVE: 'active',
  FINISHED: 'finished',
};

export const PARIS_COORDINATES = {
  lat: 48.8566,
  lng: 2.3522,
};

export const LOCAL_STORAGE = {
  COOKIES_POLICY: (userId) => `${userId}_cookies-accepted`,
  COOKIES_POLICY_REFUSE: (userId) => `${userId}_cookies-refused`,
  COOKIES_POLICY_DATE: (userId) => `${userId}_cookies-accept-date`,
};

export const DEFAULT_PRIZE_NAMES = [
  {
    custom_name: 'Win 1',
    db_number: 1,
  },
  {
    custom_name: 'Win 2',
    db_number: 2,
  },
  {
    custom_name: 'Win 3',
    db_number: 3,
  },
];

export const SOCIAL_ICONS = {
  admin: {
    FACEBOOK: 'facebook-colored',
    TWITTER: 'twitter',
  },
  user: {
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
  },
};

export const MEDIA_BREAKPOINTS = {
  MOBILE: '375px',
  TABLET: '768px',
  PRELAPTOP: '1280px',
  LAPTOP: '1365px',
  FULL: '1900px',
};

export const MEDIA_MIME_TYPES = {
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  html: 'text/html',
  app: 'application/octet-stream',
};

export const ACCEPT_MEDIA_FORMATS = '.jpg,.jpeg,.png,.pdf,.mpeg,.mp4,.html';
