export default {
  CLEAR_INTERFACES_STATE: '@@interfaces/CLEAR_INTERFACES_STATE',

  GET_INTERFACES_REQUEST: '@@interfaces/GET_INTERFACES_REQUEST',
  GET_INTERFACES_SUCCESS: '@@interfaces/GET_INTERFACES_SUCCESS',
  GET_INTERFACES_ERROR: '@@interfaces/GET_INTERFACES_ERROR',

  GET_INTERFACE_REQUEST: '@@interfaces/GET_INTERFACE_REQUEST',
  GET_INTERFACE_SUCCESS: '@@interfaces/GET_INTERFACE_SUCCESS',
  GET_INTERFACE_ERROR: '@@interfaces/GET_INTERFACE_ERROR',

  GET_INTERFACES_FOR_VALIDATION_REQUEST: '@@interfaces/GET_INTERFACES_FOR_VALIDATION_REQUEST',
  GET_INTERFACES_FOR_VALIDATION_SUCCESS: '@@interfaces/GET_INTERFACES_FOR_VALIDATION_SUCCESS',
  GET_INTERFACES_FOR_VALIDATION_ERROR: '@@interfaces/GET_INTERFACES_FOR_VALIDATION_ERROR',

  PATCH_INTERFACE_REQUEST: '@@interfaces/PATCH_INTERFACE_REQUEST',
  PATCH_INTERFACE_SUCCESS: '@@interfaces/PATCH_INTERFACE_SUCCESS',
  PATCH_INTERFACE_ERROR: '@@interfaces/PATCH_INTERFACE_ERROR',

  CREATE_INTERNAL_INTERFACE_REQUEST: '@interfaces/CREATE_INTERNAL_INTERFACE_REQUEST',
  CREATE_INTERNAL_INTERFACE_SUCCESS: '@interfaces/CREATE_INTERNAL_INTERFACE_SUCCESS',
  CREATE_INTERNAL_INTERFACE_ERROR: '@interfaces/CREATE_INTERNAL_INTERFACE_ERROR',

  DELETE_INTERFACE_REQUEST: '@interfaces/DELETE_INTERFACE_REQUEST',
  DELETE_INTERFACE_SUCCESS: '@interfaces/DELETE_INTERFACE_SUCCESS',
  DELETE_INTERFACE_ERROR: '@interfaces/DELETE_INTERFACE_ERROR',

  DELETE_INTERFACE_ITEM_REQUEST: '@interfaces/DELETE_INTERFACE_ITEM_REQUEST',
  DELETE_INTERFACE_ITEM_SUCCESS: '@interfaces/DELETE_INTERFACE_ITEM_SUCCESS',
  DELETE_INTERFACE_ITEM_ERROR: '@interfaces/DELETE_INTERFACE_ITEM_ERROR',

  CREATE_INTERFACE_ITEM_REQUEST: '@interfaces/CREATE_INTERFACE_ITEM_REQUEST',
  CREATE_INTERFACE_ITEM_SUCCESS: '@interfaces/CREATE_INTERFACE_ITEM_SUCCESS',
  CREATE_INTERFACE_ITEM_ERROR: '@interfaces/CREATE_INTERFACE_ITEM_ERROR',

  PATCH_INTERFACE_ITEM_REQUEST: '@interfaces/PATCH_INTERFACE_ITEM_REQUEST',
  PATCH_INTERFACE_ITEM_SUCCESS: '@interfaces/PATCH_INTERFACE_ITEM_SUCCESS',
  PATCH_INTERFACE_ITEM_ERROR: '@interfaces/PATCH_INTERFACE_ITEM_ERROR',

  CREATE_DEVICE_INTERFACE_REQUEST: '@interfaces/CREATE_DEVICE_INTERFACE_REQUEST',
  CREATE_DEVICE_INTERFACE_SUCCESS: '@interfaces/CREATE_DEVICE_INTERFACE_SUCCESS',
  CREATE_DEVICE_INTERFACE_ERROR: '@interfaces/CREATE_DEVICE_INTERFACE_ERROR',

  UPDATE_DEVICE_INTERFACE_REQUEST: '@interfaces/UPDATE_DEVICE_INTERFACE_REQUEST',
  UPDATE_DEVICE_INTERFACE_SUCCESS: '@interfaces/UPDATE_DEVICE_INTERFACE_SUCCESS',
  UPDATE_DEVICE_INTERFACE_ERROR: '@interfaces/UPDATE_DEVICE_INTERFACE_ERROR',

  CREATE_WEB_APP_INTERFACE_REQUEST: '@interfaces/CREATE_WEB_APP_INTERFACE_REQUEST',
  CREATE_WEB_APP_INTERFACE_SUCCESS: '@interfaces/CREATE_WEB_APP_INTERFACE_SUCCESS',
  CREATE_WEB_APP_INTERFACE_ERROR: '@interfaces/CREATE_WEB_APP_INTERFACE_ERROR',

  PATCH_WEB_APP_INTERFACE_REQUEST: '@interfaces/PATCH_WEB_APP_INTERFACE_REQUEST',
  PATCH_WEB_APP_INTERFACE_SUCCESS: '@interfaces/PATCH_WEB_APP_INTERFACE_SUCCESS',
  PATCH_WEB_APP_INTERFACE_ERROR: '@interfaces/PATCH_WEB_APP_INTERFACE_ERROR',

  REMOVE_WEB_APP_INTERFACE_REQUEST: '@interfaces/REMOVE_WEB_APP_INTERFACE_REQUEST',
  REMOVE_WEB_APP_INTERFACE_SUCCESS: '@interfaces/REMOVE_WEB_APP_INTERFACE_SUCCESS',
  REMOVE_WEB_APP_INTERFACE_ERROR: '@interfaces/REMOVE_WEB_APP_INTERFACE_ERROR',

  TESTING_WEB_APP_INTERFACE_REQUEST: '@interfaces/TESTING_WEB_APP_INTERFACE_REQUEST',
  TESTING_WEB_APP_INTERFACE_SUCCESS: '@interfaces/TESTING_WEB_APP_INTERFACE_SUCCESS',
  TESTING_WEB_APP_INTERFACE_ERROR: '@interfaces/TESTING_WEB_APP_INTERFACE_ERROR',
};
