import React from 'react';

import cn from 'classnames';
import moment from 'moment';

import bem from 'client/services/bem';
import {parseDate} from 'client/services/utils/date';

import InputWrapper, {InputWrapperProps} from 'client/common/inputs/input-wrapper';

import {Translation} from 'client/models/language/types';

import {DatepickerBase} from './datepicker-base';

import cssModule from './datepicker-input.module.scss';

const b = bem('datepicker-input', {cssModule});

type DatepickerInputProps = InputWrapperProps & {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  errorMessage?: Translation | null;
  warningMessage?: Translation;
  disabledDayFrom?: string | Date | null;
  disabledDayTo?: string | Date | null;
  disabledDayBefore?: string | Date | null;
  disabledDayAfter?: string | Date | null;
  placeholder?: Translation;
};

const DatepickerInput: React.FC<DatepickerInputProps> = (props) => {
  const {
    label,
    className,
    inputClassName,
    disabledDayBefore,
    disabledDayAfter,
    disabledDayFrom,
    disabledDayTo,
    errorMessage,
    warningMessage = '',
    labelClassName,
    errorClassName,
    required = false,
    readOnly,
    ...inputProps
  } = props;

  let status;
  if (errorMessage) {
    status = 'error' as const;
  } else if (warningMessage) {
    status = 'warning' as const;
  }

  return (
    <InputWrapper
      className={className}
      label={label}
      classNames={{label: labelClassName, error: errorClassName}}
      required={required}
      errorMessage={errorMessage}
      warning={warningMessage}
      readOnly={readOnly}
      name={inputProps.name}
      value={parseDate(inputProps.value, {outputFormat: 'DATE'})}
    >
      <DatepickerBase
        {...inputProps}
        disabledDates={[
          disabledDayBefore
            ? {
                before: moment(parseDate(disabledDayBefore, {})).toDate(),
              }
            : false,
          disabledDayAfter
            ? {
                after: moment(parseDate(disabledDayAfter, {})).toDate(),
              }
            : false,
          {
            from: disabledDayFrom ? moment(parseDate(disabledDayFrom, {})).toDate() : undefined,
            to: disabledDayTo ? moment(parseDate(disabledDayTo, {})).toDate() : undefined,
          },
        ]}
        status={status}
        className={cn(b('field'), inputClassName)}
      />
    </InputWrapper>
  );
};

export default DatepickerInput;
