import React, {PropsWithChildren} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {Translation} from 'client/models/language/types';

import cssModule from './input-wrapper.module.scss';

export type InputWrapperProps = {
  label?: Translation;
  className?: string;
  name?: string;
  errorMessage?: Translation | null;
  warning?: Translation;
  required?: boolean;
  readOnly?: boolean;
  value?: string;
  classNames?: {
    label?: string;
    error?: string;
    warning?: string;
    content?: string;
  };
};

type Props = PropsWithChildren<InputWrapperProps>;

const b = bem('input-wrapper', {cssModule});

const InputWrapper: React.FC<Props> = (props) => {
  const {label, children, classNames, className, name, required, warning, errorMessage, readOnly, value} = props;

  return (
    <div className={cn(b(), className)}>
      {label && (
        <label className={cn(b('label', classNames?.label))} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={cn(b('content', {readOnly}), classNames?.content)}>{readOnly ? value : children}</div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} className={classNames?.error} />}
      {warning && !errorMessage && <WarningMessage warningMessage={warning} className={classNames?.warning} />}
    </div>
  );
};

export default InputWrapper;
